import Image from "next/image";
import { useRouter } from "next/router";

export default function Home() {
  const router = useRouter();

  return (
    <>
      <main>
        <center>
          <Image
            src={"/images/landing-img.png"}
            alt="landing image"
            layout="responsive"
            width={100}
            height={100}
            fill={false}
            objectFit="contain"
          />
          <div
            style={{
              width: "70%",
              marginTop: "40px",
              backgroundColor: `var(--secondary-color)`,
              padding: "10px 25px 3px 25px",
              textAlign: "center",
              borderRadius: "25px",
            }}
            onClick={() => {
              router.push("/rule");
            }}
          >
            <h3 className="header-main text-white">ร่วมเล่นกิจกรรม</h3>
          </div>
        </center>
      </main >
    </>
  );
}
